import * as React from "react";
import {
    Button,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import queryString from "query-string";
import { navigate } from "gatsby-link";
import { v1 as uuidv1 } from "uuid";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";
import { Heading } from "../components/heading";
import { resetPassword } from "../features/account/client";
import { addMessage } from "../features/alerts/messagesSlice";

const useStyles = makeStyles((theme) => ({
    form: {
        width: `100%`, // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ResetPasswordPage({ location }) {
    const dispatch = useDispatch();
    const { token } = queryString.parse(location.search);
    const [showPassword, setShowPassword] = React.useState(false);
    const [password, setPassword] = React.useState(``);
    const classes = useStyles();
    const { mutateAsync, isLoading, isError } = useMutation(resetPassword, {
        onSuccess: () => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Password reset`,
                    priority: `low`,
                })
            );
            navigate(`/dashboard`);
        },
    });
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await mutateAsync({ token, password }); /* Fire and forget */
            setPassword(``);
        } catch (error) {
            //
        }
    };
    const validateForm = () => {
        return !isLoading && password.length > 0;
    };

    return (
        <Layout>
            <SEO
                keywords={[`ccg`, `collectible`, `card`, `game`, `trade`]}
                title="Reset Password"
            />
            <Heading className="mb-4" text="Reset Password" />
            <Paper className="md:w-1/2 p-4">
                {isError ? (
                    <Typography>
                        Sorry, there's something wrong with this password reset
                        link. Please request a new password reset link.
                    </Typography>
                ) : (
                    <form
                        autoComplete="off"
                        className="form"
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={
                                                showPassword
                                                    ? `Hide password`
                                                    : `Show password`
                                            }
                                            edge="end"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            id="outlined-basic"
                            key=""
                            label="New Password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            type={showPassword ? `text` : `password`}
                            value={password}
                            variant="outlined"
                        />
                        <Button
                            className={classes.submit}
                            color="primary"
                            disabled={!validateForm()}
                            fullWidth
                            type="submit"
                            variant="contained"
                        >
                            Save new password
                        </Button>
                    </form>
                )}
            </Paper>
        </Layout>
    );
}

export default ResetPasswordPage;
